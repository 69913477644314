.tAndC {
  padding: 80px 20px 20px;

  font-size: 14px;
}

.tAndC > ol > li:last-of-type {
  margin-bottom: 20px;
}

.tAndC h2 {
  text-align: left;
}

.tAndC h3 {
  width: 90%;
}

.tAndC li > ul {
  width: 90%;
  margin: 5px auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  list-style: disc inside;
}

.tAndC li > ul > li {
  width: 100%;

  text-align: left;
}

footer {
  background-color: #fffff0;
}

@media all and (max-width: 799px) {
  .tAndC > ol {
    list-style: decimal inside;
  }

  .tAndC > ol > li {
    padding: 5px;
  }

  .tAndC h2 {
    text-align: center;
  }
}
@font-face {
  font-family: 'Bahnschrift Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Bahnschrift Regular'), url('BAHNSCHRIFT 1.woff') format('woff');
  }
  
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-align: center;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: #fffff0;
  border-left: 1px dashed #572e8d;
}

::-webkit-scrollbar-thumb {
  background-color: #572e8d;
  border-right: 1px dashed #fffff0;
}

body {
  font-family: "Bahnschrift Regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
}

#section {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 20px;
  left: 0;
}

h1 {
  margin-top: 12px;
  margin-bottom: 12px;
  padding: 8px;
  position: relative;

  color: #572e8d;
}

h2 {
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 4px;

  color: #572e8d;
}

p {
  max-width: 800px;
  margin: 0 auto;
  padding: 15px 0;
  text-align: left;
}

div > ul {
  padding: 30px 0;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  list-style: none;
}

div > li {
  margin: 10px 5px;
  padding: 10px 20px;
}

a,
button,
.btn-submit {
  color: #000000;
  text-decoration: none;

  cursor: pointer;
  transition: all 500ms ease-out;
}

a:hover,
button:hover,
.btn-submit:hover {
  color: #572e8d;
}

div.home,
div.maciekjarmula,
div.pricing,
div.portfolio,
div.contact,
div.tAndC {
  min-height: calc( 100vh - 20px );
  width: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  background: #fffff0;
}

.parallax {
  width: 100%;
  padding: 70px 10px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.parallax:first-of-type {
  width: 100%;
  padding: 100px 10px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.hide {
  height: 0;
  visibility: hidden;
}

.horizontalCarousel {
  height: 80px;
  justify-content: space-evenly;
}

.horizontalCarousel > li {
  width: 40%;
}

.horizontalCarousel > li:first-of-type,
.horizontalCarousel > li:last-of-type {
  width: 20%;
  font-size: 12px;
}

@media all and (max-width: 799px) {
  body {
    font-size: 16px;
  }

  h1 {
    font-size: 24px;
  }

  .parallax {
    display: none;
    background-attachment: scroll;
  }

  .parallax:first-of-type {
    padding: 70px 10px;
    display: inline;
  }

  .horizontalCarousel {
    height: 150px;
    justify-content: space-evenly;
  }
  
  .horizontalCarousel > li {
    width: auto;
  }
  
  .horizontalCarousel > li:first-of-type {
    width: auto;
    font-size: 20px;
    transition: all 500ms ease-in-out;
  }

  .horizontalCarousel > li:last-of-type {
    width: auto;
  }
}
header {
  position: fixed;
  top: 0;
  height: 100px;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  z-index: 100;

  background: #fffff0;
  transition: height 500ms ease-in-out, background-color 280ms;
}

header > .arrow {
  display: none;

  font-family: 'Nanum Gothic Coding', monospace;
  background: #fffff0;
  color: #572e8d;
  font-size: 40px;
  font-weight: 600;
  border-radius: 35%;

  animation-name: Pulsate;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
}

header > .logo {
  padding: 0 0 25px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: all 500ms ease-in-out;
}

header > .logo > img {
  padding: 8px;
  height: 100px;
  width: 100px;

  transition: all 500ms ease-in-out;
}

header > .logo > h1 {
  padding: 25px 8px 0;

  color: #572e8d;
  transition: all 500ms ease-in-out;
}

header > ul {
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;

  list-style: none;
}

header > ul > li {
  padding: 6px 15px 4px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  list-style: none;
  transition: all 500ms ease-in-out;
}

@media all and (max-width: 799px) {
  header {
    width: 100vw;
    justify-content: center;
  }

  header > .logo > img {
    position: absolute;
    top: -5px;
    left: calc( 50% - 40px );
    height: 80px;
    width: 80px;
  }

  header > .logo > h1 {
    display: none;
  }

  header > ul {
    width: 100%;
    margin-bottom: 0;
    justify-content: space-around;
  }

  header > ul > li {
    width: 28%;
    padding: 0;
  }

  header > ul > li > a {
    width: 100%;
    padding: 2px;

    text-align: center;
  }
}

@keyframes Pulsate {
  0% { opacity: 1 }
  50% { opacity: .6 }
  100% { opacity: 1 }
}
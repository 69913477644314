.maciekjarmula {
  padding-top: 80px;
}

.maciekjarmula > .profileWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.maciekjarmula .left {
  width: 40vw;
}

.maciekjarmula .right {
  width: 55vw;
  max-width: 800px;
}

.maciekjarmula .right > * {
  text-align: left;
}

.maciekjarmula .right > h1 {
  padding: 0;
}

.maciekjarmula .right > h3 {
  margin: 4px 0;
}

.maciekjarmula > .profileWrapper > div > img {
  width: 30vw;
  max-width: 400px;
  height: auto;
  max-height: 400px;

  image-rendering: optimizeSpeed;
  border-radius: 15%;
}

.priorities {
  width: 85vw;
  margin: 30px 0 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.priorities > div > img {
  max-width: 80px;
  padding: 4px;

  border: 2px solid #572e8d;
  border-radius: 15%;
}

.priorities > div > p {
  text-align: center;
  max-width: 25vw;
}

.maciekjarmula > ul {
  width: 90vw;
  max-width: 800px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.maciekjarmula > ul > li {
  width: 100%;
  margin: 4px 0;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  border: 1px solid #572e8d;
  border-radius: 12px;
}

.maciekjarmula > ul > li > p {
  width: 15%;
  height: 40px;
  
  display: flex;
  align-items: center;
  justify-content: center;
}

.maciekjarmula > ul > li > div {
  width: 85%;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  border-left: 1px solid #572e8d;
}

.maciekjarmula > ul > li > div > .skill {
  width: 0;
  height: 40px;

  align-self: flex-start;
  background-color: #572e8d;
}

.maciekjarmula > ul > li > div > p {
  margin: 0 10px;
  position: absolute;

  color: #fffff0;
}

.maciekjarmula > .aboutMe {
  padding-bottom: 30px;
}

@media all and (max-width: 799px) {
  .maciekjarmula {
    padding-top: 110px;
  }

  .priorities {
    width: 95vw;

    flex-direction: column;
    align-items: center;
  }

  .priorities > div {
    width: 85vw;
  }

  .priorities > div > p {
    max-width: 75vw;

    text-align: left;
  }

  .maciekjarmula > ul > li {
    flex-direction: column;

    align-items: center;
    border: none;
  }

  .maciekjarmula > ul > li > div {
    border: 1px solid #572e8d;
  }
}
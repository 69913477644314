.prices > ul {
  padding-bottom: 0;
  flex-direction: column;
  align-items: flex-start;
}

.prices > ul > li {
  margin: 15px 0;
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border: 2px solid #572e8d;
  border-radius: 12px;
}

.prices > ul > li:first-of-type {
  margin-top: 0;
}

.prices > ul > li:last-of-type {
  margin-bottom: 0;
}

.prices > ul > li {
  margin: 15px 0;
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border: 2px solid #572e8d;
  border-radius: 12px;
}

.prices > ul * {
  text-align: left;
}

.prices > ul > li > button {
  width: 100px;
  padding: 6px 8px;
  align-self: flex-end;

  outline: none;
  font-weight: 600;
  text-align: center;
  background-color: transparent;
  border: 1px solid #572e8d;
  border-radius: 9px;
}

.prices > ul > li > button:hover {
  border: 1px solid #000000;
}

.prices p {
  width: 80%;
  margin-right: 20%;
}

.prices li > div > h3 {
  padding: 15px 0 5px;
}

.prices li > div > ul {
  padding: 5px 0;
  display: flex;
  flex-direction: column;

  list-style: none;
  text-align: left;
}

h2.price {
  width: auto;
  align-self: flex-end;
}

.contactButton {
  width: 100%;
  padding: 30px 0;

  border-bottom: 2px solid #572e8d;
}

.contactMessage {
  margin-bottom: 30px;
  font-weight: 600;
  text-align: center;
}

.contactButton > a {
  padding: 6px 8px 4px;

  font-weight: 600;
  line-height: 20px;
  border: 1px solid #572e8d;
  border-radius: 9px;
}

.contactButton > a:hover {
  border-color: #000000;
}
.parallaxPortfolio {
  background-image: url( "/images/coding.jpg" );
}

.portfolio > .portfolioHeader {
  width: 100%;

  border-top: 2px solid #572e8d;
  border-bottom: 2px solid #572e8d;
}

.portfolio > .portfolioHeader > h1 {
  margin: 30px
}

.portfolioGrid {
  width: 100%;
  min-height: 280px;
  
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  /* border-top: 2px solid #572e8d; */
  border-bottom: 2px solid #572e8d;
}

.portfolioGrid p {
  margin: 0;
  padding: 0;
}

.partner {
  margin: 15px 0;
}

.partner:hover {
  transform: scale( 1.02 );
}

.partner > a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border: 2px solid #572e8d;
  border-radius: 12px;
}

.thumbnail {
  width: 320px;
  height: 220px;

  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  border-radius: 9px;
}

.partnerDescr {
  width: 320px;
  height: 220px;
  padding: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.number1 {
  background-image: url( "/images/partners/bondPartner20190425.png" );
}

.number2 {
  background-image: url( "/images/partners/bndco20190614.png" );
}

.number3 {
  background-image: url( "/images/partners/sarahKadrnka20190831.png" );
}

.number4 {
  background-image: url( "/images/partners/4pee.png" );
}

@media all and (max-width: 799px) {
  .partner:hover {
    transform: none;
  }

  .partner > a {
    flex-direction: column;
  }

  .partnerDescr {
    height: 120px;
  }
}
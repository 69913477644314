.formWrapper {
  width: 100%;
  display: flex;
  justify-content: center;

  border-top: 2px solid #572e8d;
  border-bottom: 2px solid #572e8d;
}

#contactForm {
  width: 80%;
  max-width: 800px;
  min-height: 380px;
  padding: 30px 0;
  padding-top: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contactFields {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#contactForm .inputs {
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

#contactForm input {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

#contactForm label {
  margin: 10px 0;
  align-self: flex-start;
  line-height: 16px;
}

#contactForm .textarea {
  width: 60%;
  display: flex;
  flex-direction: column;
}

#contactForm textarea {
  resize: none;
}

#contactForm input,
#contactForm textarea {
  padding: 4px 10px;

  color: #572e8d;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  border: 1px solid #000000;
  border-radius: 9px;
  outline: none;
}

#contactForm > .btn-submit {
  width: 200px;
  margin-top: 30px;
  padding: 4px 8px;
  
  display: flex;
  align-items: center;
  align-self: center;
  
  background-color: #fffff0;
  font-weight: 600;
  border: 1px solid #572e8d;
  border-radius: 9px;
  cursor: pointer;
}

#contactForm > .btn-submit:hover {
  border: 1px solid #000000;
}

#contactForm > .btn-submit.success,
#contactForm > .btn-submit.error,
#contactForm > .confirmation,
#contactForm > .refusal {
  display: none;
}

#contactForm > .confirmation.success,
#contactForm > .refusal.error {
  width: 100%;
  display: block;
  text-align: center;
}

@media all and (max-width: 799px) {  
  .contactFields {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  
  #contactForm .inputs {
    width: 80%;
  }
  
  #contactForm .inputs > input {
    width: 100%;
  }
  
  #contactForm .textarea {
    width: 80%;
  }
  
  #contactForm .textarea > textarea {
    width: 100%;
  }
}
.socialMedia {
  margin: 20px auto 0;
  width: 100%;
  max-width: 800px;

  display: flex;
  justify-content: space-around;
  align-items: center;
}

.socialMedia > a {
  margin: 0 5px;
  padding: 4px;

  font-weight: 600;
}


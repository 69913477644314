footer {
  height: 20px;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;

  display: flex;
  justify-content: space-around;
  align-items: center;

  z-index: 100;

  border-top: 2px solid #572e8d;
}

footer > p {
  max-width: none;
  width: 100vw;
  padding: 2px;
  text-align: center;
  font-size: 12px;
  background-color: rgba( 255, 255, 240, .5);
}

@media all and (max-width: 799px) {
  footer {
    background-attachment: scroll;
    background-position: bottom;
  }
}
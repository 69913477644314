.team {
  width: 100%;
  padding: 30px 10%;

  border-top: 2px solid #572e8d;
  border-bottom: 2px solid #572e8d;
}

.members {
  width: 100%;
  
  display: flex;
  flex-direction: column;
}

.member {
  width: 100%;
  margin: 15px 0;
  padding: 15px 0;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  border: 2px solid #572e8d;
  border-radius: 12px;
}

.member:first-of-type {
  margin-top: 0;
}

.member:last-of-type {
  margin-bottom: 0;
}

.member h2 {
  padding: 15px 0;
}

.member h3 {
  padding: 5px 0;
}

.member > div > img {
  width: 25vw;
  max-width: 300px;
  height: auto;
  max-height: 300px;

  image-rendering: optimizeSpeed;
  border-radius: 15%;
}

.memberLeft {
  width: 40vw;
}

.memberRight {
  width: 55vw;
  max-width: 800px;
}

.memberRight > * {
  text-align: left;
}

.memberRight > a {
  padding: 4px 8px;

  color: #000000;
  border: 1px solid #572e8d;
  border-radius: 9px;
  text-decoration: none;

  cursor: pointer;
  transition: all 500ms ease-out;
}

.memberRight > a:hover {
  color: #572e8d;
  border-color: #000000;
}

@media all and (max-width: 799px) {
  .member {
    flex-direction: column;
  }

  .member > div > img {
    width: 50vw;
    max-width: 250px;
    height: auto;
    max-height: 250px;
    margin: 0 auto;
  }

  .memberLeft {
    width: 100%;
  }
  
  .memberRight {
    width: 100%;
  }
}
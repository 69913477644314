.contact > .parallaxContact {
  background-image: url( "/images/mobile.jpg" );
  background-position: center;
}

.openingTimes {
  width: 100%;

  border-top: 2px solid #572e8d;
  border-bottom: 2px solid #572e8d;
}

.weekdaysContact {
  width: 300px;
  height: 200px;
  margin: 30px auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.weekdaysContact > li {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.otherContact {
  width: 100%;

  border-top: 2px solid #572e8d;
  border-bottom: 2px solid #572e8d;
}

.otherContact > p {
  padding: 8px 0;

  margin-bottom: 36px;
  text-align: center;
}

@media all and (max-width: 799px) {  
  .parallaxContact:nth-of-type( 3 ) {
    display: inline;
    background-attachment: fixed;
    border-bottom: 2px solid #572e8d;
  }

  .openingTimes {
    border-top: none;
    border-bottom: none;
  }

  .weekdaysContact {
    margin: 0 auto;
  }

  .otherContact {
    border-top: none;
  }
}